import IWeeklyTotal from "../../interfaces/insights/IWeeklyTotal";
import {formatNumber} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class WeeklyTotal extends InsightComponent<IWeeklyTotal> {
  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/weekly_total_spend.png';
  }
  headline(): string {
    return formatNumber(this.props.total_spend, true, { roundingOverride: 0 });
  }

  byline(): string {
    if(this.props.costed) {
      return 'total spent last week';
    }
    return 'worth of electricity used last week';
  }
  title(): string {
    return 'Weekly Total Spend';
  }
}
