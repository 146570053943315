import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./NotFound.scss";

export default class NotFound extends React.Component<{}> {
  render() {
    return (
      <div className="container">
        <div className="not-found">
          <Header />
          <div className="main-section">
            <div className="headline">Not Found</div>
            <div className="content">
              <p>
                Sorry we couldn't find what you were looking for, please check
                the link.
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
