import {Navigate, useParams} from "react-router-dom";
import {isoDate, mondayLastWeek} from "../services/Utils";

function DefaultFromDateNavigation() {
  const defaultDate = mondayLastWeek();
  const { accountId } = useParams();

  return (<Navigate to={ `/usage/${accountId}/usage/${isoDate(defaultDate)}` } replace={true} />);
}

export default DefaultFromDateNavigation;
