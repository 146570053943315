import {useParams} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./UtilityPage.scss";

function UnsubscribeConfirmation() {
  const { accountId } = useParams();
  return (
    <div className="container">
      <div className="subscription utility-page">
        <Header />
        <div className="main-section">
          <div className="content">
            <p>You have been successfully unsubscribed from Power Tool</p>
            <p>
              If you wish to re-subscribe, go to the 'Usage' page within <a href="https://auth.momentumenergy.com.au/">MyAccount</a> or <a href="https://www.momentumenergy.com.au/contact-us">contact us</a>.
            </p>
          </div>
        </div>
        <Footer accountId={accountId as string} />
      </div>
    </div>
  );
}

export default UnsubscribeConfirmation;
