import IBillingPeriod from "../../interfaces/insights/IBillingPeriod";
import {formatNumber} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class EstimatedNextBill extends InsightComponent<IBillingPeriod> {
  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/estimated_next_bill.png';
  }
  headline(): string {
    return formatNumber(this.props.expected_next_bill_amount as number, true, { roundingOverride: 0 });
  }
  byline(): string {
    return 'estimated cost of your next bill';
  }
  title(): string {
    return 'Next Bill Estimate';
  }
}
