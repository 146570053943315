import classNames from "classnames"
import React, {ReactElement} from "react"
import IMobileSelectableInsight from "../../interfaces/insights/IMobileSelectableInsight"

export default abstract class InsightComponent<T extends IMobileSelectableInsight> extends React.Component<T> {
  abstract iconSrc(): string;
  abstract headline(): string;
  abstract byline(): string | ReactElement;
  abstract title(): string;

  render() {
    return (
      <div className={ classNames({ insight: true, selected: this.props.selected }) } title={ this.title() }>
        <div className="insight-icon">
           <img src={ this.iconSrc() } alt={ this.title() } />
        </div>
        <div className="insight-headline">
          { this.headline() }
        </div>
        <div className="insight-byline">
          { this.byline() }
        </div>
      </div>
    )
  }
}
