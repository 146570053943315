import React from "react";
import { dayLabels, nonMouseDevice, usingDesktopView } from "../services/Utils";
import * as d3 from "d3";

export default class UsageDataComponent<T> extends React.Component<T> {
  componentRef: React.RefObject<HTMLDivElement>;
  dayLabels: { [key: string]: string }[];
  hasSolar: boolean;
  swiping: boolean;
  clickAnywhereListener: (event: any) => void;
  tooltipBarElement: SVGRectElement | null;
  legendTooltipElement: HTMLElement | null;
  legendSwiping: boolean;

  constructor(props: T) {
    super(props);
    this.componentRef = React.createRef<HTMLDivElement>();
    this.dayLabels = dayLabels();
    this.hasSolar = false;
    this.swiping = false;
    this.legendSwiping = false;
    this.clickAnywhereListener = (event) => this.handleClickAnywhere(event);
    this.tooltipBarElement = null;
    this.legendTooltipElement = null;
  }

  handleClickAnywhere(event: any): void {
    if(!event.srcElement.matches('rect')) {
      this.hideTooltip();
    }

    if(!event.srcElement.matches('.legend-info')) {
      if(usingDesktopView()) {
        this.hideLegendTooltip();
      }
    }
  }

  tooltipHTML(data: { [key: string]: number }): string {
    throw new Error("Must be implemented by subclass");
  }

  initialiseLegendTooltip() {
    const legendContainer = this.componentRef.current?.querySelector('.legend-container') as HTMLElement;
    const tooltip = legendContainer.querySelector('.legend-tooltip-container') as HTMLElement;
    const closeButton = tooltip.querySelector('.close') as HTMLElement;

    if(!closeButton.dataset['closeListener']) {
      closeButton.addEventListener('click', () => this.hideLegendTooltip());
      closeButton.dataset['closeButton'] = 'true';
    }

    legendContainer.querySelectorAll('.legend-info')?.forEach((element) => {

      if(usingDesktopView()) {
        element = element.closest('.title') as HTMLElement
      }
      // not mouse driven device
      element.addEventListener('mouseover', (event) => {
        if(nonMouseDevice()) {
          // not mouse driven device
          return;
        }

        this.handleLegendTapOrHover(event.currentTarget as HTMLElement, tooltip);
      });


      element.addEventListener('mouseout', () => {
        if(nonMouseDevice()) {
          return;
        }

        this.hideLegendTooltip();
      });
      element.addEventListener('touchstart', () => {
        this.legendSwiping = false;
      });
      legendContainer.addEventListener('touchmove', () => {
        this.legendSwiping = true;

        if(usingDesktopView()) {
          this.hideLegendTooltip();
        }
      });

      element.addEventListener('touchend', (event) => {
        this.handleLegendTapOrHover(event.currentTarget as HTMLElement, tooltip);

        return false;
      });


    });
  }
  hideLegendTooltip() {
    document.querySelector('.legend-tooltip-container')?.classList.remove('visible');
    this.legendTooltipElement = null;
  }

  legendTooltipHTML(element: HTMLElement): { title: string; content: string; } {
    if(element.matches('.title')) {
      element = element.querySelector('.legend-info') as HTMLElement;
    }
    if(element.matches('.usage')) {

      return {title: 'Usage', content: 'Refers to the electricity you use.' };
    } 
    else if(element.matches('.dailyaverage, .average')) {
      return this.averageTooltipHTML();
    }
    else if(element.matches('.solarexport')) {
      return { title: 'Solar export', content: 'The amount of solar energy you export to the grid.' };
    } else if(element.matches('.supply')) {
      return { title: 'Supply charge', content: 'A fixed amount per day paid to connect you to the energy network.' };
    }

    return { title: '', content: '' };
  }

  averageTooltipHTML(): { title: string; content: string } {
    throw new Error("Method shoud be implement by sub class");
  }

  handleLegendTapOrHover(hoverElement: HTMLElement, tooltip: HTMLElement) {
    if(this.legendSwiping) {
      this.legendSwiping = false;
      return;
    }

    if(hoverElement === this.legendTooltipElement) {
      this.hideLegendTooltip()
      return;
    }

     const legendHtml = this.legendTooltipHTML(hoverElement);
    (tooltip.querySelector('.legend-tooltip-content') as HTMLElement).innerHTML = legendHtml.content;
    (tooltip.querySelector('.legend-tooltip-title') as HTMLElement).innerHTML = legendHtml.title;

    this.showLegendTooltip(tooltip, hoverElement);
  }

  showLegendTooltip(tooltip: HTMLElement, hoverElement: HTMLElement) {
    if(hoverElement.matches('.title')) {
      hoverElement = hoverElement.querySelector('.legend-info') as HTMLElement;
    }


    this.legendTooltipElement = hoverElement;


    if(usingDesktopView()) {
      tooltip.classList.remove('top');
      tooltip.classList.add('transparent');
      tooltip.classList.add('visible');
      const rect = hoverElement.getBoundingClientRect();
      let elementTop = hoverElement.offsetTop;
      let elementLeft = hoverElement.offsetLeft;

      const tooltipRect = tooltip.getBoundingClientRect();

      tooltip.style.top = `${elementTop - tooltipRect.height - 10}px`;
      tooltip.style.left = `${elementLeft + (rect.width / 2) - (tooltipRect.width / 2) - 5}px`;

      if((tooltip.getBoundingClientRect()?.top || 0) < window.screenY) {
        tooltip.classList.add('top')
        tooltip.style.top = `${elementTop + hoverElement.offsetHeight + 10}px`;
      }
      tooltip.classList.remove('transparent');
    } else {
      tooltip.classList.add('visible');
    }
  }

  initialiseTooltip(
    bars: d3.Selection<SVGRectElement, any, SVGGElement, unknown>,
    tooltip: d3.Selection<d3.BaseType, unknown, null, undefined>,
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
  ) {
    const thisInstance = this;

    bars.on("mouseover", function (event, data) {
      if (nonMouseDevice()) {
        return;
      }

      thisInstance.handleTapOrHover(event, tooltip, data, this);
    });

    bars.on("mouseout", function (event, data) {
      thisInstance.hideTooltip();
    });
    bars.on("touchstart", () => {
      this.swiping = false;
    });
    svg.on("touchmove", () => {
      this.swiping = true;
      this.hideTooltip();
    });

    bars.on("touchend", function (event, data) {
      thisInstance.handleTapOrHover(event, tooltip, data, this);

      return false;
    });
  }
  hideTooltip() {
    d3.select(".tooltip-container").classed("visible", false);
    d3.select("rect.background-bars.active").classed("active", false);
    this.tooltipBarElement = null;
  }

  handleTapOrHover(
    event: any,
    tooltip: d3.Selection<d3.BaseType, unknown, null, undefined>,
    data: { [key: string]: number },
    barElement: SVGRectElement
  ) {
    if (this.swiping) {
      this.swiping = false;
      return;
    }

    const eventY = event.changedTouches
      ? event.changedTouches[0]?.pageY || 0
      : event.pageY;
    const rect = d3.select(barElement).node()?.getBoundingClientRect();
    const width = rect?.width || 0;
    let x = (rect?.x || 0) + width / 2;

    tooltip
      .html(this.tooltipHTML(data))
      .classed("visible", true)
      .classed("transparent", true);

    const tooltipHeight = (tooltip.node() as HTMLElement).clientHeight || 0;
    const tooltipWidth =
      (tooltip.node() as HTMLElement).getBoundingClientRect().width || 0;

    let y = eventY - tooltipHeight - 10;
    x -= tooltipWidth / 2.0;
    let bubblePointLeft = tooltipWidth / 2.0 - 10;
    let leftPointer = false;

    if (x + tooltipWidth > window.innerWidth) {
      this.handleBarTooFarRight(tooltip, barElement, y, event, data);
      return false;
    } else if (x < 0) {
      x = 0;
      bubblePointLeft =
        barElement.getBoundingClientRect().left +
        barElement.getBoundingClientRect().width / 2;

      if (bubblePointLeft + 10 > barElement.getBoundingClientRect().right) {
        bubblePointLeft = 0;
        leftPointer = true;
        x = barElement.getBoundingClientRect().right;
      }
    }
    this.toggleTooltip(tooltip, x, y, bubblePointLeft, barElement, {
      leftPointer: leftPointer,
    });
  }
  handleBarTooFarRight(
    tooltip: d3.Selection<d3.BaseType, unknown, null, undefined>,
    barElement: SVGRectElement,
    y: number,
    event: any,
    data: { [key: string]: number }
  ) {
    const tooltipWidth =
      (tooltip.node() as HTMLElement).getBoundingClientRect().width || 0;
    let x: number;
    let bubblePointLeft: number;
    let rightPointer = false;

    if ((barElement.getBoundingClientRect()?.right || 0) > window.innerWidth) {
      barElement.scrollIntoView();
      const checkScroll = () => {
        if ((barElement.getBoundingClientRect()?.right || 0) > window.innerWidth) {
          setTimeout(checkScroll, 100);
        } else {
          this.handleTapOrHover(event, tooltip, data, barElement);
        }
      };
      setTimeout(checkScroll, 200);
    } else {
      x = (barElement.getBoundingClientRect()?.right || 0) - tooltipWidth;
      bubblePointLeft =
        tooltipWidth - (barElement.getBoundingClientRect()?.width || 0) / 2;
      if (bubblePointLeft + 20 > tooltipWidth) {
        x = (barElement.getBoundingClientRect()?.left || 0) - tooltipWidth - 10;
        rightPointer = true;
        bubblePointLeft = tooltipWidth - 6;
      }

      this.toggleTooltip(tooltip, x, y, bubblePointLeft, barElement, {
        rightPointer: rightPointer,
      });
    }
  }
  toggleTooltip(
    tooltip: d3.Selection<d3.BaseType, unknown, null, undefined>,
    x: number,
    y: number,
    bubblePointLeft: number,
    barElement: SVGRectElement,
    options: {
      leftPointer?: boolean;
      rightPointer?: boolean;
    } = { leftPointer: false, rightPointer: false }
  ) {
    if (this.tooltipBarElement === barElement) {
      this.hideTooltip();
      return;
    }
    this.tooltipBarElement = barElement;
    d3.select("rect.background-bars.active").classed("active", false);
    (tooltip.node() as HTMLElement).style.setProperty(
      "--bubble-point-left",
      `${bubblePointLeft}px`
    );
    tooltip
      .style("top", `${y}px`)
      .style("left", `${x}px`)
      .classed("visible", true)
      .classed("transparent", false)
      .classed("left", !!options.leftPointer)
      .classed("right", !!options.rightPointer);

    d3.select(barElement).classed("active", true);
  }

  render() {
    return (
      <div ref={this.componentRef} className="usage-data">
        <div className="tooltip-container"></div>
        <div className="graph-container" grid-area="graph-container"></div>
        <div className="legend-container" grid-area="legend-container">
          <div className="legend-tooltip-container">
            <div className="legend-tooltip-title"></div>
            <div className="legend-tooltip-content"></div>
            <div className="close">&times;</div>
          </div>
        </div>
      </div>
    );
  }

  drawChart() {
    throw new Error("Must be implemented by subclass");
  }

  componentDidMount(): void {
    this.drawChart();
    document.body.addEventListener("touchend", this.clickAnywhereListener);
    this.refreshLegendStyle();
    this.initialiseLegendTooltip();
  }

  componentWillUnmount(): void {
    document.body.removeEventListener("touchend", this.clickAnywhereListener);
  }

  isCosted(): boolean {
    throw new Error("Must be implemented by subclass");
  }

  componentDidUpdate(): void {
    d3.select(this.componentRef.current)
    .select(".graph-container")
    .selectAll("*")
    .remove();
    d3.select(this.componentRef.current)
    .select(".legend-container")
    .selectAll(".legend")
    .remove();
    this.drawChart();
    this.refreshLegendStyle();
    this.initialiseLegendTooltip();
  }

  refreshLegendStyle() {
    const legendList = document.querySelector(
      ".legend-container .legend ul"
    ) as HTMLElement;
    legendList?.style?.setProperty(
      "--legend-section-count",
      `${legendList?.querySelectorAll("li")?.length}`
    );
  }
}
