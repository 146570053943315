import React from "react";
import './HeaderFooter.scss';

export default class Header extends React.Component<{}> {
  render() {
    return(<div className="header">
      <img src="https://cdn.billcap.com/momentum/momentum_logo.png" className="logo" alt="Momentum Energy" />
      <div></div>
      <img src="https://cdn.billcap.com/momentum/powertool_logo.png" className="product-logo" alt="Power Tool" />
    </div>);
  }
}
