import axios from "axios";
import ITrustInsights from "../interfaces/ITrustInsights";
import ITrustState from "../interfaces/ITrustState";
import MockDataService from "./MockDataService";
import {isoDate} from "./Utils";

const { REACT_APP_MOCK_DATA } = process.env;


export class TrustService {
  static get accountNotFound(): string {
    return 'ACCOUNT_NOT_FOUND';
  }

  static insightsCache: { [key: string]: ITrustInsights } = {};

  trackVisit(accountId: string, name: string, additionalProperties: { [key: string]: any } = {}) {
    let visitData = {
      source: 'trust',
      type: 'tracker_ui_visit',
      account_identifier: accountId,
      page_name: name,
      url: document.URL,
      additional_data: Object.assign({
        event_time: new Date().toISOString()
      }, additionalProperties)
    }

    axios.post('/api/web_interactions', visitData)
  }

  fetchInsights(accountId: string, fromDate: Date, costed: boolean): Promise<ITrustState> {
    if(REACT_APP_MOCK_DATA === 'true') {
      return this.mockData(accountId, fromDate, costed);
    }
   
    return new Promise<ITrustState>((resolve: Function, reject: Function) => {
      const params = new URLSearchParams();
      const cacheKey = `${accountId}|${isoDate(fromDate)}|${costed}`;

      if(TrustService.insightsCache[cacheKey]) {
        const insightsData: ITrustInsights = TrustService.insightsCache[cacheKey];

        resolve({
          accountId: accountId,
          fromDate: new Date(insightsData.current_week.metadata.from),
          costed: insightsData.current_week.metadata.costed,
          insights: insightsData,
          error: false
        });

        return;
      }

      params.append('account_id', accountId);
      params.append('from', isoDate(fromDate));
      params.append('costed', costed.toString());
      params.append('client_code', 'MOMENTUM');
      this.trackVisit(accountId, 'insights_api_request', { code_position: new Error('For stack trace').stack })

      axios.get(`/api/tracker/usage?${params.toString()}`)
        .then((response : any) => {
    if(response.data) {
            const insightsData: ITrustInsights = response.data as ITrustInsights;

            TrustService.insightsCache[cacheKey] = insightsData;

            resolve({
              accountId: accountId,
              fromDate: new Date(insightsData.current_week.metadata.from),
              costed: insightsData.current_week.metadata.costed,
              insights: insightsData,
              error: false
            });
          } else {
            resolve({
              accountId: accountId,
              fromDate: fromDate,
              costed: costed,
              insights: null,
              error: false
            });
          }
        }).catch((error) => {
          if(error.response?.data?.error === 'invalid token') {
            reject(TrustService.accountNotFound);
          } else {
            reject(error)
          }
        });
    });
  }
  updateSubscription (accountId: string, unsubscribeFrom: string, subscriptionStatus: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      axios.post('/api/tracker/subscription', {
	account_id: accountId,
	client_code: 'MOMENTUM',
	data: {
          type: 'Subscriptions::WeeklyEmail',
          subscribed: subscriptionStatus,
          sub_type: unsubscribeFrom
	}
      }).then(response => {
	resolve(response.data.subscribed);
      }).catch(e => reject(e))
    });
  }

  mockData(accountId: string, fromDate: Date, costed: boolean): Promise<ITrustState> {
    return new Promise<ITrustState>((resolve, reject) => {
      try {
        let result = new MockDataService().retrieveMockedData(fromDate, costed);

        if(!result) {
          reject('Could not find mock data');
        }

        result = result as ITrustInsights;

        resolve({
          accountId: accountId,
          fromDate: new Date(result.current_week.metadata.from),
          costed: result.current_week.metadata.costed,
          insights: result,
          error: false
        });
      }
      catch(error) {
        reject(error);
      }
    });
  }
}
