import IHouseholdSize from "../../interfaces/insights/IHouseholdSize";
import InsightComponent from "./InsightComponent";

export default class HouseholdSize extends InsightComponent<IHouseholdSize> {
  static readonly householdSizes: { [key:string]: string } = {
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    five: '5+'
  };

  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/household_size.png';
  }
  headline(): string {
    return `${HouseholdSize.householdSizes[this.props.similar_to_household.split('_')[0]]} person`;
  }
  byline(): string {
    return 'the average household size your usage represents';
  }
  title(): string {
    return 'Household Size Benchmark';
  }
}
