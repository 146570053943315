import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./UtilityPage.scss";

export default class AccountNotFound extends React.Component<{}> {
  render() {
    return (
      <div className="container">
        <div className="account-not-found utility-page">
          <Header />
          <div className="main-section">
            <div className="headline">We’re still powering up.</div>
            <div className="content">
              <p>
                Power Tool isn’t ready for you right now. Once you’re connected,
                it can take up to 7 days to collect your usage data.
              </p>
              <p>Check back soon.</p>
              <img
                className="img"
                src="https://cdn.billcap.com/momentum/tool_box.png"
                alt="Powertool toolbox"
              />
              <p>
                For more details, visit our{" "}
                <Link
                  className="link"
                  to="https://www.momentumenergy.com.au/powertool"
                >
                  FAQs
                </Link>
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
