import IWeeklySolarExport from "../../interfaces/insights/IWeeklySolarExport";
import {formatPercentageOrTimes} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class SolarTrend extends InsightComponent<IWeeklySolarExport> {
  iconSrc(): string {
    if(this.props.trend === "more") {
      return 'https://cdn.billcap.com/momentum/solar_trend_up.png';
    } else {
      return 'https://cdn.billcap.com/momentum/solar_trend_down.png';
    }
  }

  headline(): string {
    return `${formatPercentageOrTimes(Math.abs(this.props.trend_percentage))} ${this.props.trend}`;
  }

  byline(): string {
    return `${this.props.costed ? 'feed-in credits earned' : 'solar exported'} than the previous week`;
  }

  title(): string {
    return 'Solar Export Trend';
  }
}
