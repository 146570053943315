import classNames from "classnames";
import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { TrustService } from "../services/TrustService";
import { useParams } from "react-router";
import "./UtilityPage.scss";

function Unsubscribe() {
  const [message, setMessage] = useState({ status: "ok", message: "" });
  const { accountId } = useParams();

  const closeMessage = (): void => {
    setMessage({ status: "ok", message: "" });
  };

  const unsubscribeCustomer = (unsubscribeFrom: string): void => {
    let unsubscribeFromText: string;
    switch (unsubscribeFrom) {
      case "powerTool":
        unsubscribeFromText = "Power Tool";
        break;
      case "marketing":
        unsubscribeFromText = "Marketing";
    }
    new TrustService()
      .updateSubscription(accountId as string, unsubscribeFrom, false)
      .then((_subscriptionStatus) => {
        setMessage({
          status: "ok",
          message: `You have succesfully unsubscribed from ${unsubscribeFromText}`,
        });
      })
      .catch((_e) => {
        setMessage({
          status: "error",
          message:
            "We're sorry but we were unable to unsubscribe you, Please contact support if this issue persists",
        });
      });
  };

  return (
    <div className="container">
      <div className="subscription utility-page">
        <Header />
        <div className="main-section">
          <div
            className={classNames({
              alert: true,
              hidden: message.message.trim() === "",
              ok: message.status === "ok",
              error: message.status === "error",
            })}
          >
            <span>{message.message}</span>
            <button onClick={(_e) => closeMessage()}>&#10006;</button>
          </div>
          <div className="headline" style={{ marginTop: "9rem" }}>
            Unsubscribe
          </div>
          <div className="content" style={{ marginBottom: "9rem" }}>
            <p className="sub-heading">Power Tool</p>
            <p>
              Don’t want to get Power Tool emails? No sweat. This will remove Power Tool for this address only.
            </p>
            <button
              onClick={(_e) => unsubscribeCustomer("powerTool")}
              className="unsubscribe-btn"
            >
              UNSUBSCRIBE FROM POWER TOOL
            </button>
            <p className="sub-heading">Marketing</p>
            <p>
              Special offers, new plan information and Power Tool clogging up your inbox? We get it.
            </p>
            <button
              onClick={(_e) => unsubscribeCustomer("marketing")}
              className="unsubscribe-btn"
            >
              UNSUBSCRIBE FROM MARKETING
            </button>
          </div>
        </div>
        <Footer accountId={accountId as string} />
      </div>
    </div>
  );
}

export default Unsubscribe;
