import {format} from "date-fns";
import IBillingPeriod from "../../interfaces/insights/IBillingPeriod";
import {sundayLastWeek} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class BillingDaysLeft extends InsightComponent<IBillingPeriod> {
  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/billing_days_left.png';
  }
  headline(): string {
    return `${this.props.days_left} days`;
  }
  byline(): string {
    return `left in the billing cycle as of ${format(sundayLastWeek(), 'dd/MM/yyyy')}`;
  }
  title(): string {
    return `left in the billing cycle as of ${format(sundayLastWeek(), 'dd/MM/yyyy')}`;
  }
}
