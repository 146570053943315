import * as d3 from "d3";
import { ITrustInsightsInterval } from "../interfaces/ITrustInsightsInterval";
import { formatNumber } from "../services/Utils";
import colours from "./colours.json";
import "./graph.scss";
import {
  IDailyIntervalData,
  ITrustDailyInterval,
} from "../interfaces/ITrustDailyInterval";
import UsageDataComponent from "./UsageDataComponent";
import { format } from "date-fns";

export default class HourlyUsageData extends UsageDataComponent<{
  interval: ITrustInsightsInterval;
  costed: boolean;
}> {
  isCosted(): boolean {
    return this.props.costed;
  }

  get minimumValue(): number {
    const intervalValues = Object.values(this.props.interval.intervals);
    if (this.isCosted()) {
      if (intervalValues.some((d) => d.data.total_spend < 0)) {
        return (
          d3.min(intervalValues, (interval) => interval.data.total_spend) || 0
        );
      } else {
        return 0;
      }
    } else {
      if (intervalValues.some((d) => (d.data.total_spend - d.data.export) < 0)) {
        return (
          d3.min(intervalValues, (interval) => (interval.data.total_spend - interval.data.export)) || 0
        );
      } else {
        return 0;
      }
    }
  }

  averageTooltipHTML(): { title: string; content: string } {
    return { title: 'Average',  content: 'Calculated on usage at that time of the week for the preceding 4 weeks.' };
  }



  get intervalValues() {
    const result: ITrustDailyInterval[] = [];

    Object.keys(this.props.interval.intervals)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .forEach((k) => {
        result.push(this.props.interval.intervals[k]);

        if (
          this.isCosted()
            ? this.props.interval.intervals[k].data.export < 0
            : this.props.interval.intervals[k].data.export !== 0
        ) {
          this.hasSolar = true;
        }
      });

    return result;
  }

  get intervalValuesData() {
    return this.intervalValues.map((d, index) =>
      Object.assign({ index: index }, d.data)
    );
  }

  tooltipHTML(data: { [key: string]: number }): string {
    const costed = this.isCosted();
    const currentInterval = this.props.interval.intervals[data.index];
    return `<div 
    class="graph-tooltip"
    title="Tooltip for ${this.formatTime(
      currentInterval.interval_range.starts_at.time
    )} - ${this.formatTime(currentInterval.interval_range.ends_at.time)}"
    >
    <div class="tooltip-title">
      ${this.formatTime(
        currentInterval.interval_range.starts_at.time
      )} - ${this.formatTime(currentInterval.interval_range.ends_at.time)}
    </div>
    <div class="tooltip-subtitle">
      ${
        costed
          ? `Cost ${formatNumber(currentInterval.data.total_spend, costed)}`
          : formatNumber(currentInterval.data.total_spend, costed)
      }
    </div>
    <div class="tooltip-data">
     <div class="usage data-point"></div>
     <div>Usage ${formatNumber(data.consumption, costed)}</div>
     ${
       this.hasSolar
         ? `
         <div class="solar data-point"></div>
         <div>Solar ${formatNumber(data.export, costed)}</div>
         `
         : ""
     }
     <div class="average data-point"></div>
         <div>Average ${formatNumber(data.average_spend, costed)}</div>
     </div>
  </div>`;
  }

  formatTime(time: string) {
    let date = this.props.interval.interval_range.ends_at.yyyymmdd as string;
    let dateString = (date.match(/^\d{4}/) || [])[0] as string;
    dateString += "-";
    dateString += (date.match(/^\d{4}(\d{2})/) || [])[1] as string;
    dateString += "-";
    dateString += (date.match(/^\d{4}\d{2}(\d{2})/) || [])[1] as string;

    return format(new Date(`${dateString}T${time}`), "hh:mmaa");
  }

  drawChart() {
    var timeArray: string[] = [];
    for (var i = 0; i < 24; i++) {
      for (var j = 0; j < 2; j++) {
        timeArray.push(`${i}:${j === 0 ? "00" : 30 * j}`);
      }
    }

    const data = this.intervalValuesData;

    // Set margins so that the graph sits nicely in the svg that's created
    const margin = { top: 80, right: 60, bottom: 40, left: 80 };
    const usage: Array<{
      label: string;
      key: string;
      backgroundColor: string;
    }> = [];
    if (data.some((t) => t.export !== 0)) {
      usage.push({
        label: "Solar export",
        key: "export",
        backgroundColor: colours.whiteLabelSolarExport,
      });
    }
    if (data.some((t) => t.consumption > 0)) {
      usage.push({
        label: "Usage",
        key: "consumption",
        backgroundColor: colours.whiteLabelUsage,
      });
    }

    if (data.some((t) => t.average_spend > 0)) {
      usage.push({
        label: "Average",
        key: "average_spend",
        backgroundColor: colours.whiteLabelAverage,
      });
    }
    // top level svg elements
    let width = 970 * 2;
    var svg = d3
      .select(this.componentRef.current)
      .select(".graph-container")
      .append("svg")
      .attr("class", "usagechart")
      .attr("viewBox", `0 0 ${width} 600`)
      .attr("title", "Half Hourly Usage Graph")
      .attr("background-color", colours.white)
      .attr("width", width);
    var g = svg
      .append("g")
      .attr("class", "chart")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    var bounds = svg.node()?.getBoundingClientRect();
    const height = (bounds?.height as number) - margin.top - margin.bottom;

    // set the scales for the x and y axis
    var xScale = d3
      .scaleBand()
      .range([0, 1280 - margin.left - 5])
      .padding(0.1)
      .domain(data.map((d, i) => `${i}`));
      let maxValue: number
    if (this.isCosted()) {
      maxValue = d3.max(data, (interval) => interval.total_spend) || 0;
    } else {
      maxValue = d3.max(data, (interval) => interval.total_spend - interval.export) || 0;
    }
    maxValue = Math.max(
      maxValue,
      d3.max(data, (interval) => interval.average_spend) || 0
    );
    var yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([this.minimumValue, maxValue])
      .nice();
    var xAxis = d3.axisTop(xScale).tickFormat((d, i) => {
      return timeArray[i];
    });
    var yAxis = d3
      .axisLeft(yScale)
      .tickPadding(15)
      .ticks(7)
      .tickFormat((d) =>
        formatNumber(d.valueOf(), this.props.costed, {}, true)
      );
    // background bars
    var bars = g
      .append("g")
      .attr("class", "bars")
      .selectAll(".background-bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "background-bars")
      .attr("x", (d: IDailyIntervalData, i: number): number => {
        return xScale(`${i}`) || 0;
      })
      .attr("y", -12)
      .attr("width", () => xScale.bandwidth())
      .attr("height", height + 12)
      .attr("fill", colours.white)
      .style("cursor", "pointer")
      .attr("title", (d, i: number): string => {
        const currentInterval = this.props.interval.intervals[i];
        return `${this.formatTime(
          currentInterval.interval_range.starts_at.time
        )} - ${this.formatTime(
          currentInterval.interval_range.ends_at.time
        )} Graph Bar`;
      });
    g.append("g")
      .attr("class", "x-axis")
      .attr("title", "X Axis")
      .attr("transform", "translate(0, -50)")
      .attr("fill", "#333")
      .style("font-size", "1rem")
      .style("font-weight", 400)
      .style("color", "black")
      .call(xAxis);
    // create the y-axis
    g.append("g")
      .attr("class", "y-axis")
      .attr("title", "Y Axis")
      .style("font-size", "1rem")
      .style("font-weight", 400)
      .style("color", "black")
      .call(yAxis);
    // create the bars
    g.selectAll(".bar")
      .data(() => data)
      .enter()
      .append("rect")
      .attr(
        "x",
        (d: IDailyIntervalData, i: number): number => xScale(`${i}`) || 0
      )
      .attr("title", (d: IDailyIntervalData, i: number): string => {
        const currentInterval = this.props.interval.intervals[i];
        return `${this.formatTime(
          currentInterval.interval_range.starts_at.time
        )} - ${this.formatTime(currentInterval.interval_range.ends_at.time)}`;
      })
      .attr("y", (d) => {
        if (this.isCosted()) {
          if (d.total_spend > 0) {
            return yScale(d.total_spend);
          } else {
            return yScale(0);
          }
        } else {
          if (d.total_spend - d.export > 0) {
            return yScale(d.total_spend - d.export);
          } else {
            return yScale(0);
          }
        }
      })
      .attr("width", () => xScale.bandwidth())
      .attr("height", (d) => {
        if(this.isCosted()) {
          return Math.abs(yScale(d.total_spend) - yScale(0));
        } else {
          return Math.abs(yScale(d.total_spend - d.export) - yScale(0));
        }
      })
      .attr("class", (d) => {
        if(this.isCosted()) {
          if (d.total_spend > 0) {
            return "positive";
          } else {
            return "negative";
          }
        } else {
          if ((d.total_spend - d.export) > 0) {
            return "positive";
          } else {
            return "negative";
          }
        }
      })
      .attr("pointer-events", "none");
    //        .attr('opacity', (node, index) => {
    //          return index === this.activeBar ? 1 : 0.3
    //        })
    // averages
    //

    var tooltip = d3
      .select(this.componentRef.current)
      .select(".tooltip-container");

    this.initialiseTooltip(bars, tooltip, svg);

    g.selectAll(".bar")
      .data(() => data)
      .enter()
      .append("g")
      .attr("class", "averages")
      .selectAll(".average")
      .data(data.map((i) => i.average_spend))
      .enter()
      .append("circle")
      .attr("cx", (d, i) => {
        return (xScale(`${i}`) || 0) + xScale.bandwidth() / 2;
      })
      .attr("cy", (d) => yScale(d))
      .attr("r", 6)
      .attr("fill", colours.whiteLabelAverage)
      .attr("stroke", "white")
      .attr("stroke-width", "0");
    // remove the X and Y axis lines and ticks
    d3.selectAll(".domain").remove();
    d3.selectAll(".x-axis .tick line").remove();
    d3.selectAll(".y-axis .tick line")
      .attr("x2", width - 2)
      .attr("stroke", (i) =>
        i === 0 ? colours.whiteLabelAxisLineZero : colours.whiteLabelAxisLine
      );
    // only show every fourth marker
    var ticks = d3.selectAll(".x-axis .tick text");

    ticks.attr("class", function (d, i) {
      if (i % 4 !== 0) {
        d3.select(this).remove();
      }
      return "";
    });

    var tickLines = d3.selectAll(".x-axis .tick line");
    tickLines.attr("class", function (d, i) {
      if (i % 4 !== 0) {
        d3.select(this).remove();
      }

      return "";
    });
    // legend
    var legend = d3
      .select(this.componentRef.current)
      .select(".legend-container")
      .append("div")
      .attr("class", "legend")
      .append("ul");
    var keys = legend
      .selectAll(".key")
      .data(usage)
      .enter()
      .append("li")
      .attr("class", "key");
    var keyTitles = keys.append("div").attr("class", "title");
    keyTitles
      .append("svg")
      .attr("width", 14)
      .attr("height", 14)
      .append("circle")
      .attr("cx", 7)
      .attr("cy", 7)
      .attr("r", 7)
      .attr("stroke", "black")
      .attr("stroke-width", 0)
      .style("fill", (d) => d.backgroundColor);
    keyTitles
      .append("p")
      .html((d, i) => {
        return usage[i].label;
      })
      .each(function (d, i) {
        d3.select(this)
          .append("img")
          .attr('class', `legend-info ${usage[i].label.replace(/\s+/g, '').toLowerCase()}`)
          .attr("src", (d, i) => {
            return "https://cdn.billcap.com/momentum/info-icon.png";
          })
          .style("margin-left", "7px");
      });
  }
}
