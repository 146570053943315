import classNames from "classnames";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import INavWeekProperties from "../interfaces/INavWeekProperties";
import {
  costedState,
  currentWeekFromInsights,
  formatNumber,
  oneDayMilliseconds,
  oneWeekMilliseconds,
  toDateString,
} from "../services/Utils";
import "./DateNav.scss";

export default class NavWeek extends React.Component<INavWeekProperties> {
  get fromDateForPreviousWeek() {
    return toDateString(
      new Date(this.props.fromDate.getTime() - oneWeekMilliseconds())
    );
  }

  get fromDateForNextWeek() {
    return toDateString(
      new Date(this.props.fromDate.getTime() + oneWeekMilliseconds())
    );
  }

  get fromDateForNextWeekInFuture(): boolean {
    return (
      new Date(this.props.fromDate.getTime() + oneWeekMilliseconds()) >
      new Date()
    );
  }

  get currentWeekDescription() {
    const currentIntervalRange = currentWeekFromInsights(
      this.props
    )?.interval_range;

    if (!currentIntervalRange && this.props.fromDate) {
      return `${format(this.props.fromDate, "dd MMM yyyy")} - ${format(
        new Date(
          this.props.fromDate.getTime() +
            oneWeekMilliseconds() -
            oneDayMilliseconds()
        ),
        "dd MMM yyyy"
      )}`;
    }

    const startDateString = [
      currentIntervalRange?.starts_at?.date,
      currentIntervalRange?.starts_at?.month_short,
      currentIntervalRange?.starts_at?.yyyymmdd?.substring(0, 4),
    ].join(" ");

    const endDateString = [
      currentIntervalRange?.ends_at?.date,
      currentIntervalRange?.ends_at?.month_short,
      currentIntervalRange?.ends_at?.yyyymmdd?.substring(0, 4),
    ].join(" ");

    return `${startDateString} - ${endDateString}`;
  }

  render() {
    return (
      <div>
        <div className="date-nav">
          <Link
            to={`/usage/${this.props.accountId}/${costedState(this.props)}/${
              this.fromDateForPreviousWeek
            }`}
            className="nav-link back"
            title="Previous Week"
          ></Link>
          <div className="current-day-or-range weekly">
            {this.currentWeekDescription}
          </div>
          <Link
            to={
              this.fromDateForNextWeekInFuture
                ? "#"
                : `/usage/${this.props.accountId}/${costedState(this.props)}/${
                    this.fromDateForNextWeek
                  }`
            }
            className={classNames({
              "nav-link": true,
              forward: true,
              disabled: this.fromDateForNextWeekInFuture,
            })}
            title="Next Week"
          ></Link>

          <div className="total-spend-period">
            {formatNumber(
              currentWeekFromInsights(this.props)?.insights.weekly_total as number,
              currentWeekFromInsights(this.props)?.metadata?.costed as boolean,
            { roundingOverride: currentWeekFromInsights(this.props)?.metadata?.costed ? 2 : 0, ceil: !currentWeekFromInsights(this.props)?.metadata?.costed }
          )}
          </div>
          <div className="total-spend-description">
            TOTAL WEEKLY{" "}
            {currentWeekFromInsights(this.props)?.metadata?.costed
              ? "SPEND"
              : "USAGE"}
          </div>
        </div>
      </div>
    );
  }
}
