import classNames from "classnames";
import React from "react";
import IWeeklyUsage from "../interfaces/insights/IWeeklyUsage";
import ISummaryNavProperties from "../interfaces/ISummaryNavProperties";
import {currentWeekFromInsights, formatNumber} from "../services/Utils";
import NavDay from "./NavDay";
import NavWeek from "./NavWeek";
import './SummaryNav.scss'

export default class SummaryNav extends React.Component<ISummaryNavProperties> {
  get currentWeekInsights() {
    return this.props.insights?.current_week.insights;
  }

  get weeklyUsageInsight(): IWeeklyUsage {
    return this.currentWeekInsights?.fixed_insights.weekly_usage as IWeeklyUsage;
  }

  get costed() {
    return this.props.insights?.current_week.metadata.costed;
  }

  render() {
    return(
      <div className="summary-nav">
        <div className="hero-insight">
          {
            currentWeekFromInsights(this.props)
            ?
          (<React.Fragment>
            <div className="hero-value">
              <div>
                <img alt="Week on week spend variance" src="https://cdn.billcap.com/momentum/hero_trend_up.png" 
                  className={ classNames({ 'trend-down': this.weeklyUsageInsight.total_current_week < this.weeklyUsageInsight.total_previous_week }) } 
                />
                { formatNumber(Math.abs(this.weeklyUsageInsight.trend_percentage as number), false, { unitDescriptionOverride: '%', roundingOverride: 0, ceil: true }) }         
              </div>
            </div>
            <div className="hero-byline">
              compared to previous week
            </div>
          </React.Fragment>) : (<div></div>)
          }
        </div>
        <div className="nav-day-week">
          { this.props.hourlyView ? 
            (<NavDay 
              fromDate={ this.props.fromDate } 
              accountId={ this.props.accountId } 
              insights={ this.props.insights } 
              selectedDay={ this.props.selectedDay as string }
              costed={ this.props.costed }
              reloading={ this.props.reloading }
            ></NavDay>) : 
            (<NavWeek 
              fromDate={this.props.fromDate} 
              accountId={this.props.accountId} 
              insights={ this.props.insights }
              costed={this.props.costed}
            ></NavWeek>)
          }
        </div>
        <div className="hero-insight">
          {
            currentWeekFromInsights(this.props) ?
          (<React.Fragment>
          <div className="hero-value">
            <div>
            <img alt="Week on week spend variance" 
              src="https://cdn.billcap.com/momentum/hero_trend_up.png" 
              className={ classNames({ 'trend-down': this.weeklyUsageInsight.weekly_average_trend_percentage < 0 }) } />
            { formatNumber(Math.abs(this.weeklyUsageInsight.weekly_average_trend_percentage as number), false, { unitDescriptionOverride: '%', roundingOverride: 0, ceil: true }) }         
          </div>
        </div>
          <div className="hero-byline">
            compared to usual
          </div>
        </React.Fragment>) : (<div></div>)
          }
        </div>
      </div>
  )
  }
}
