import {Provider} from "@rollbar/react";
import {ReactElement} from "react";
import {createBrowserRouter, createMemoryRouter, RouteObject, RouterProvider} from "react-router-dom";
import Rollbar from "rollbar";
import DefaultFromDateNavigation from "../pages/DefaultFromDateNavigation";
import HealthCheck from "../pages/HealthCheck";
import NotFound from "../pages/NotFound";
import SettingsRedirect from "../pages/SettingsRedirect";
import Subscription from "../pages/Subscription";
import MainComponent from "./MainComponent";
import ErrorBoundary from "./ErrorBoundary";
import AccountNotFound from "../pages/AccountNotFound";
import UnsubscribeConfirmation from "../pages/UnsubscribeConfirmation";

export default function usageRouterWrapper(type: 'browser' | 'memory', initialRoute: string | null = null): ReactElement {
  const routeObjects: RouteObject[] = [
    {
      element: (
        <ErrorBoundary>
          <MainComponent costed={ true }/>
        </ErrorBoundary>
      ),
      path: "/usage/:accountId/cost/:fromDate/:selectedDay?"
    },
    {
      element: (
        <ErrorBoundary>
          <MainComponent costed={ false }/>
        </ErrorBoundary>
      ),
      path: "/usage/:accountId/usage/:fromDate/:selectedDay?"
    },
    {
      element: (
        <ErrorBoundary>
          <DefaultFromDateNavigation/>
        </ErrorBoundary>
      ),
      path: '/usage/:accountId'
    },
    {
      element: <SettingsRedirect />,
      path: '/settings/:accountId'
    },
    {
      element: (
        <ErrorBoundary>
          <Subscription />
        </ErrorBoundary>
      ),
      path: '/:accountId/subscription'
    },
    {
      element: (
        <ErrorBoundary>
          <UnsubscribeConfirmation />
        </ErrorBoundary>
      ),
      path: '/:accountId/unsubscribe_confirmation'
    },
    {
      element: (
        <ErrorBoundary>
          <AccountNotFound />
        </ErrorBoundary>
        ),
      path: '/accountNotFound'
    },
    {
      element: <HealthCheck />,
      path: '/healthcheck'
    },
    {
      element: <NotFound/>,
      path: '*'
    }
  ];

  if(type === 'browser') {
    let router = createBrowserRouter(routeObjects);
    const rollbarConfig: Rollbar.Configuration = {   
      accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
      environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        client: {
          javascript: {
            code_version: '1.0.0',
            source_map_enabled: true
          }
        }
      }
    };

    return (
      <Provider config={ rollbarConfig }>
        <RouterProvider router={router} />
      </Provider>
    );
  } else {
    const routerOpts: { initialEntries?: any } = {};

    if(initialRoute) {
      routerOpts.initialEntries = [ initialRoute ];
    }

    let router = createMemoryRouter(routeObjects, routerOpts);
    return (<RouterProvider router={router} />);
  }
}
