import INightlyUsage from "../../interfaces/insights/INightlyUsage";
import {formatPercentageOrTimes} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class NightlyUsage extends InsightComponent<INightlyUsage> {
  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/nightly_usage.png';
  }

  headline(): string {
    if(this.props.weekly_average_trend_percentage < 0) {
      return `${formatPercentageOrTimes(Math.abs(this.props.weekly_average_trend_percentage))} less`;
    } else {
      return `${formatPercentageOrTimes(Math.abs(this.props.weekly_average_trend_percentage))} more`;
    }
  }

  byline(): string {
    return `was ${ this.props.costed ? 'spent' : 'used' } overnight than usual`;
  }

  title(): string {
    return 'Nightly Usage';
  }
}
