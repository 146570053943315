import classNames from "classnames";
import {format} from "date-fns";
import React from "react";
import IBillingPeriod from "../interfaces/insights/IBillingPeriod";
import IDrillDownProperties from "../interfaces/insights/IDrillDownProperties";
import IWeeklyTotal from "../interfaces/insights/IWeeklyTotal";
import ITrustInsights from "../interfaces/ITrustInsights";
import ITrustState from "../interfaces/ITrustState";
import { TrustService } from "../services/TrustService";
import { mondayLastWeek, sundayLastWeek } from "../services/Utils";
import "./DrillDown.scss";
import InsightsPanel from "./InsightsPanel";

interface BillingPeriodInsights { [key: string]: IBillingPeriod | IWeeklyTotal }

export default class DrillDown extends React.Component<
  IDrillDownProperties,
  { costedInsights?: ITrustInsights; loading: boolean }
> {
  constructor(props: IDrillDownProperties) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    if (this.state.loading) {
      this.loadInsights();
    }
  }

  loadInsights() {
    new TrustService()
      .fetchInsights(this.props.accountId, mondayLastWeek(), true)
      .then((state: ITrustState) => {
        this.setState({
          costedInsights: state.insights,
          loading: false,
        });
      });
  }

  componentDidUpdate() {
    if (this.state.loading) {
      this.loadInsights();
    }
  }

  render() {
    return (
      <div>
        <div className="drill-down">
          <div className="rotate-phone">
            <img
              src="https://cdn.billcap.com/momentum/rotate_phone.png"
              alt="Rotate Phone for Better View"
            />
            <div>Rotate mobile for a better view</div>
          </div>
          <div className="drill-down-title">More Insights</div>
          <div className="drill-down-subtitle">
            <div>{format(mondayLastWeek(), 'dd/MM/yyyy')} - {format(sundayLastWeek(), 'dd/MM/yyyy')} </div>
          </div>
          {this.state.loading ? (
            <div
              className={classNames({
                loading: true,
                hidden: this.state && !this.state.loading,
              })}
            >
              <div className="circle-animation"></div>
            </div>
          ) : (
            this.billingInsights() ?
            <InsightsPanel insights={this.billingInsights() as BillingPeriodInsights} />
            : <div className="insights-panel">No billing information available. We'll populate when actual reads are available.</div>
          )}
        </div>
      </div>
    );
  }
  billingInsights(): BillingPeriodInsights | null {
    const currentWeekInsights =
      this.state.costedInsights?.current_week.insights;
    const billingPeriod = currentWeekInsights?.fixed_insights?.billing_period as IBillingPeriod;

    if (!billingPeriod) {
      return null;
    }

    return {
      estimated_next_bill: billingPeriod,
      billing_days_left: billingPeriod,
    };
  }
}
