import React from "react";
import {ITrustInsightsInterval} from "../interfaces/ITrustInsightsInterval";
import IUsageDataProps from "../interfaces/IUsageDataProps";
import {currentWeekFromInsights} from "../services/Utils";
import HourlyUsageData from "./HourlyUsageData";
import NoUsageData from "./NoUsageData";
import WeeklyUsageData from "./WeeklyUsageData";

export default class UsageData extends React.Component<IUsageDataProps> {
  render() {
    if(!currentWeekFromInsights(this.props) || (this.props.hourlyView && Object.keys(this.intervalsForSelectedDay().intervals).length === 0)) {
      return (<NoUsageData />);
    }
    else if(this.props.hourlyView) {
      return (<HourlyUsageData 
        interval={ this.intervalsForSelectedDay() } 
        costed={ this.props.costed }
        />);
    } else {
      return (<WeeklyUsageData insights={ this.props.insights } />);
    }
  }
  intervalsForSelectedDay(): ITrustInsightsInterval {
    return (currentWeekFromInsights(this.props)?.intervals as {[k: string]: ITrustInsightsInterval})[this.props.selectedDay as string] as ITrustInsightsInterval;
  }
}

