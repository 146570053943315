import React from "react";
import { format } from "date-fns";
import INavProperties from "../interfaces/INavProperties";
import "./TopSummary.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { isoDate } from "../services/Utils";

export class TopSummary extends React.Component<INavProperties> {
  get fromDateString() {
    return isoDate(this.props.fromDate);
  }

  get costed() {
    return this.props.insights?.current_week.metadata.costed;
  }

  render() {
    return (
      <div className="navigation top-summary">
        <div className="resp-side-by-side">
          <div className="summary">
            Your weekly Power Tool is here to help you see when and how you use
            your electricity. And, if you’re looking to manage your energy
            usage, there’s no better tool for the job.
          </div>
          <div className="costed-uncosted-links">
            <Link
              to={`/usage/${this.props.accountId}/usage/${this.fromDateString}${
                this.props.selectedDay ? `/${this.props.selectedDay}` : ""
              }`}
              className={classNames({
                costedToggle: true,
                activeMode: !this.props.costed,
                uncostedLink: true,
              })}
              title="Switch to kWh View"
            >
              Usage (kWh)
            </Link>
            <Link
              to={`/usage/${this.props.accountId}/cost/${this.fromDateString}${
                this.props.selectedDay ? `/${this.props.selectedDay}` : ""
              }`}
              className={classNames({
                costedToggle: true,
                activeMode: this.props.costed,
                costedLink: true,
              })}
              title="Switch to Costed View"
            >
              Cost ($)
            </Link>
          </div>
        </div>
      </div>
    );
  }

  formatDate(dateString: string): React.ReactNode {
    const match = dateString.match(/(\d{4})(\d{2})(\d{2})/);
    match?.shift();
    return format(new Date(match?.join("-") as string), "dd/MM/yyyy");
  }
}
