import React from "react";
import { Link } from "react-router-dom";
import INavDayProperties from "../interfaces/INavDayProperties";
import { ITrustWeekIntervalCollection } from "../interfaces/ITrustInsights";
import { ITrustInsightsInterval } from "../interfaces/ITrustInsightsInterval";
import { format } from "date-fns";
import classNames from "classnames";

import {
  costedState,
  currentWeekFromInsights,
  dayLabels,
  formatNumber,
  oneDayMilliseconds,
  oneWeekMilliseconds,
  toDateString,
} from "../services/Utils";
import "./DateNav.scss";

export default class NavDay extends React.Component<INavDayProperties> {
  dayLabels: { [key: string]: string }[];

  constructor(props: INavDayProperties) {
    super(props);
    this.dayLabels = dayLabels();
  }

  get fromDateForPreviousDay() {
    if (this.props.selectedDay === "0") {
      return toDateString(
        new Date(this.props.fromDate.getTime() - oneDayMilliseconds() * 7)
      );
    }

    return toDateString(this.props.fromDate);
  }

  get previousSelectedDay() {
    if (this.props.selectedDay === "0") {
      return "6";
    }

    return `${parseInt(this.props.selectedDay) - 1}`;
  }

  get fromDateForNextDay() {
    if (this.props.selectedDay === "6") {
      return toDateString(
        new Date(this.props.fromDate.getTime() + oneWeekMilliseconds())
      );
    }

    return toDateString(this.props.fromDate);
  }

  get nextSelectedDay() {
    if (this.props.selectedDay === "6") {
      return "0";
    }

    return `${parseInt(this.props.selectedDay) + 1}`;
  }

  get getCurrentIntervals() :ITrustWeekIntervalCollection {
    return currentWeekFromInsights(this.props)
      ?.intervals as ITrustWeekIntervalCollection;
  }
  intervalsForSelectedDay(): ITrustInsightsInterval {
    return (currentWeekFromInsights(this.props)?.intervals as {[k: string]: ITrustInsightsInterval})[this.props.selectedDay as string] as ITrustInsightsInterval;
  }

  get currentDayDescription() {
    const currentWeekIntervals = currentWeekFromInsights(this.props)
      ?.intervals as { [k: string]: ITrustInsightsInterval };
    var dayDescription = '';
    dayDescription = `Monday ${format(this.props.fromDate, "dd MMM yyyy")}`;
    if(currentWeekIntervals){
      const currentIntervals =
        currentWeekIntervals[this.props.selectedDay].interval_range;

      dayDescription = `${currentIntervals?.starts_at?.day} ${
        currentIntervals?.starts_at?.date
      } ${
        currentIntervals?.starts_at?.month_short
      } ${currentIntervals?.starts_at?.yyyymmdd?.substring(0, 4)}`;
    }
    return dayDescription;
  }

  get nextDayIsNotValid(): boolean {
    if(!this.props.insights?.next_week?.has_data && this.props.selectedDay === "6"){
      console.log("its true");
      return true;
    }
    return !currentWeekFromInsights(this.props) || Object.keys(this.intervalsForSelectedDay().intervals).length === 0;
  }

  render() {
    return (
      <div className="date-nav">
        <Link
          to={`/usage/${this.props.accountId}/${costedState(this.props)}/${
            this.fromDateForPreviousDay
          }/${this.previousSelectedDay}`}
          className="nav-link back"
          title="Previous Day"
        ></Link>
        <div className="current-day-or-range">
          {this.props.reloading ? "" : this.currentDayDescription}
        </div>
        <Link
          to={`/usage/${this.props.accountId}/${costedState(this.props)}/${
            this.fromDateForNextDay
          }/${this.nextSelectedDay}`}
          className={classNames({
            "nav-link": true,
            forward: true,
            disabled: this.nextDayIsNotValid,
          })}
          title="Next Day" 
        ></Link>
        {this.getCurrentIntervals ?
          (
        <div className="total-spend-period">
        {formatNumber(
            this.intervalsForSelectedDay().data.total_spend as number,
            currentWeekFromInsights(this.props)?.metadata?.costed as boolean,
            { roundingOverride: currentWeekFromInsights(this.props)?.metadata?.costed ? 2 : 0 }
          )}
        </div>): <div></div>}
        <div className="total-spend-description">
          TOTAL DAILY{" "}
          {currentWeekFromInsights(this.props)?.metadata?.costed
            ? "SPEND"
            : "USAGE"}
        </div>
      </div>
    );
  }
}
