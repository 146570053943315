import React from "react";
import { Link } from "react-router-dom";
import "./HeaderFooter.scss";

export default class Footer extends React.Component<{ accountId?: string }> {
  render() {
    return (
      <div className="footer">
        <img
          src="https://cdn.billcap.com/momentum/momentum_logo.png"
          className="logo"
          alt="Momentum Energy"
        />
        <p className="company-details">Power Tool Terms and Conditions</p>
        <div className="disclaimer">
          <div>
            1. Power Tool is a free service from Momentum Energy that aims to
            provide you with insights into your weekly electricity usage via a
            weekly email and online portal.
          </div>
          <div>
            2. Power Tool is available to eligible customers only, as determined
            by us from time to time.
          </div>
          <div>
            3. By subscribing to Power Tool, you agree to us collecting your
            information (including personal information) and sharing this with
            our third-party partner in accordance with our{" "}
            <Link to="https://www.momentumenergy.com.au/privacy-policy">
              Privacy Policy
            </Link>
            , in order to provide this service to you.
          </div>
          <div>
            4. While the information provided by Power Tool is based on usage
            data from your smart electricity meter, it is estimated and is
            intended to be a guide only.
          </div>
          <div>
            5. Power Tool is not a bill and is not used to determine your
            electricity usage for the purposes of billing you.
          </div>
          <div>
            6. Calculations include electricity usage charges, supply charges,
            most discounts to you and (where applicable) solar feed-in credits.
            All amounts are GST inclusive.
          </div>
          <div>
            7. We do not guarantee the accuracy or completeness of the
            information provided by Power Tool, and take no responsibility for
            any act or omission in connection with Power Tool.
          </div>
          <div>
            8. We do not guarantee that you will receive an email from Power
            Tool every week with insights into your energy use.
          </div>
          <div>
            9. We may cancel or change the operation or functionality of Power
            Tool at any time without notice to you.
          </div>
          <div>
            10. You can unsubscribe from Power Tool at any time by clicking the
            “unsubscribe” button:
          </div>
          <div>a. on the Power Tool online portal;</div>
          <div>b. at the bottom of the Power Tool email sent to you; or</div>
          <div>
            c. by logging into{" "}
            <Link to="https://auth.momentumenergy.com.au/">MyAccount</Link> and
            clicking on the Usage page.
          </div>
          <div>
            11. If you have any questions on Power Tool, please contact us by
            clicking{" "}
            <Link to="https://www.momentumenergy.com.au/contact-us">here</Link>.
          </div>
        </div>

        <div className="footer-links">
          {this.props.accountId ? (
            <Link to={`/${this.props.accountId}/subscription`}>
              Unsubscribe
            </Link>
          ) : (
            ""
          )}
          <Link to="https://www.momentumenergy.com.au/contact-us">
            Contact Us
          </Link>
          <Link to="https://www.momentumenergy.com.au/powertool">FAQs</Link>
        </div>
      </div>
    );
  }
}
