import React from "react";
import classNames from "classnames";
import './InsightsPanel.scss';
import BiggestPeriodDeviance from "./insights/BiggestPeriodDeviance";
import IBiggestPeriodDeviance from "../interfaces/insights/IBiggestPeriodDeviance";
import IHouseholdSize from "../interfaces/insights/IHouseholdSize";
import INightlyUsage from "../interfaces/insights/INightlyUsage";
import IWeeklySolarExport from "../interfaces/insights/IWeeklySolarExport";
import IWeeklyTotal from "../interfaces/insights/IWeeklyTotal";
import BillingDaysLeft from "./insights/BillingDaysLeft";
import ColdestDay from "./insights/ColdestDay";
import EstimatedNextBill from "./insights/EstimatedNextBill";
import HottestDay from "./insights/HottestDay";
import HouseholdSize from "./insights/HouseholdSize";
import NightlyUsage from "./insights/NightlyUsage";
import WeeklySolarExport from "./insights/WeeklySolarExport";
import WeeklyTotal from "./insights/WeeklyTotal";
import IBillingPeriod from "../interfaces/insights/IBillingPeriod";
import IHottestColdestDay from "../interfaces/insights/IHottestColdestDay";
import SolarTrend from "./insights/SolarTrend";

export default class InsightsPanel extends React.Component<{ insights: { [ key: string]: any } },  { selectedIndex: number }> {
  swipingElement: (EventTarget & HTMLElement) | undefined | null;
  touchStartLocation: number | undefined | null;

  constructor(props: { insights: { [ key: string ]: any } }) {
    super(props);
    this.state = { selectedIndex: 0 };
  }

  static readonly insightRenderings: { [key: string]: (data: any) => React.ReactNode } = {
    household_size_benchmark: (data: any) => (<HouseholdSize {...(data as IHouseholdSize)} />),
    coldest_day: (data: any) => (<ColdestDay {...(data as IHottestColdestDay)} />),
    weekly_solar_export: (data: any) => (<WeeklySolarExport {...(data as IWeeklySolarExport)} />),
    billing_days_left: (data: any) => (<BillingDaysLeft {...(data as IBillingPeriod)} />),
    hottest_day: (data: any) => (<HottestDay {...(data as IHottestColdestDay)} />),
    estimated_next_bill: (data: any) => (<EstimatedNextBill {...(data as IBillingPeriod)} />),
    nightly_usage: (data: any) => (<NightlyUsage {...(data as INightlyUsage)} />),
    biggest_period_deviance: (data: any) => (<BiggestPeriodDeviance {...(data as IBiggestPeriodDeviance)} />),
    weekly_total: (data: any) => (<WeeklyTotal {...(data as IWeeklyTotal)} />),
    solar_trend: (data: any) => (<SolarTrend {...(data as IWeeklySolarExport)} />)
  };

  renderInsight(name: string, data: any) {
    return InsightsPanel.insightRenderings[name](data);
  }

  selectInsight(direction: number) {
    let newSelectedIndex: number = 0;
    if(direction === -1 && this.state.selectedIndex === 0) {
      newSelectedIndex = Object.keys(this.props.insights).length - 1;
    }
    else if(direction === 1 && this.state.selectedIndex === Object.keys(this.props.insights).length - 1) {
      newSelectedIndex = 0;
    }
    else {
      newSelectedIndex = this.state.selectedIndex + direction;
    }

    this.setState(Object.assign(this.state, { selectedIndex: newSelectedIndex }));
  }

  touchStart(e: React.TouchEvent<HTMLElement>): void {
    if(e.touches.length > 1) {
      // not a single touch event

      this.swipingElement = null;

      return;
    }

    this.touchStartLocation = e.targetTouches[0].clientX;
  }

  touchCancel(e: React.TouchEvent<HTMLElement>): void {
     this.touchStartLocation = null;
     this.swipingElement = null;
  }

  touchEnd(e: React.TouchEvent<HTMLElement>): void {
    if(this.swipingElement === e.currentTarget) {
      if(e.changedTouches[0].clientX < (this.touchStartLocation as number)) {
        this.selectInsight(1);
      } else if(e.changedTouches[0].clientX > (this.touchStartLocation as number)) {
        this.selectInsight(-1)
      }
    }
  }

  swiping(e: React.TouchEvent<HTMLElement>): void {
    if(e.touches.length > 1) {
      return;
    }

    this.swipingElement = e.currentTarget;
  }


  render() {
    let content = Object.keys(this.props.insights).map((key: string, index) => (
      InsightsPanel.insightRenderings[key](Object.assign(this.props.insights[key], { selected: index === this.state.selectedIndex }))
    ));

    return (
      <div className="insights-panel">
        <div className={classNames({ 'insights-nav-direction': true, 'nav-link': true, back: true })} onClick={() => this.selectInsight(-1)}></div>
        <div className={`insights-content insights-${Object.keys(this.props.insights).length}`}
          onTouchStart={ (e) => this.touchStart(e) }
          onTouchMove= { (e) => this.swiping(e) }
          onTouchEnd= { (e) => this.touchEnd(e) }
          onTouchCancel= { (e) => this.touchCancel(e) }
>
          {content}
        </div>
        <div className={classNames({ 'insights-nav-direction': true, 'nav-link': true, forward: true})} onClick={() => this.selectInsight(1)}></div>
      </div>
    );

  }
}
