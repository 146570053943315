import insightsFrom20230501Costed from '../fixtures/insights_responses/from_2023_05_01_costed.json';
import insightsFrom20230501Uncosted from '../fixtures/insights_responses/from_2023_05_01_uncosted.json';
import insightsFrom20230508Costed from '../fixtures/insights_responses/from_2023_05_08_costed.json';
import insightsFrom20230508Uncosted from '../fixtures/insights_responses/from_2023_05_08_uncosted.json';
import insightsFrom20230515Costed from '../fixtures/insights_responses/from_2023_05_15_costed.json';
import insightsFrom20230515Uncosted from '../fixtures/insights_responses/from_2023_05_15_uncosted.json';

import ITrustInsights from '../interfaces/ITrustInsights';
import {format} from 'date-fns';

export default class MockDataService {
  mockedData: { [k: string]: ITrustInsights }  | null = null;

  get mockData() {
    if(this.mockedData) {
      return this.mockedData;
    }

    this.mockedData = {
      insightsFrom20230501Costed: insightsFrom20230501Costed,
      insightsFrom20230501Uncosted: insightsFrom20230501Uncosted,
      insightsFrom20230508Costed: insightsFrom20230508Costed,
      insightsFrom20230508Uncosted: insightsFrom20230508Uncosted,
      insightsFrom20230515Costed: insightsFrom20230515Costed,
      insightsFrom20230515Uncosted: insightsFrom20230515Uncosted
    }

    return this.mockedData;
  }

  retrieveMockedData(fromDate: Date, costed: boolean): ITrustInsights | null {
    const key = `insightsFrom${format(fromDate, 'yyyyMMdd')}${ costed ? 'Costed' : 'Uncosted'}`;

    return this.mockData[key];
  }
}
