import {format} from "date-fns";
import IBiggestPeriodDeviance from "../../interfaces/insights/IBiggestPeriodDeviance";
import {formatPercentageOrTimes} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class BiggestPeriodDeviance extends InsightComponent<IBiggestPeriodDeviance> {
  get date(): Date {
    return new Date(this.props.day);
  }

  get periodOfDay(): string {
    if(this.props.period === 'day') {
      return 'daytime';
    } else if (this.props.period === 'night') {
      return 'overnight';
    }

    return this.props.period;
  }

  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/biggest_period_deviance.png';
  }

  headline(): string {
    const dayString: string = format(this.date, 'EEE');
    return `${dayString} ${this.periodOfDay}`
  }

  byline(): string {
    return `you ${ this.props.costed ? 'spent' : 'used' } ${formatPercentageOrTimes(Math.abs(this.props.deviance))} ${this.props.deviance < 0 ? 'less' : 'more' } than usual at that time`;
  }

  title(): string {
    return "Biggest Period Deviance";
  }
}
