import * as d3 from "d3";
import IInsightsProperties from "../interfaces/IInsightsProperties";
import { ITrustWeekIntervalCollection } from "../interfaces/ITrustInsights";
import {
  currentWeekFromInsights,
  defaultZeroesForInsightsValues,
  formatNumber,
} from "../services/Utils";
import colours from "./colours.json";
import UsageDataComponent from "./UsageDataComponent";

export default class WeeklyUsageData extends UsageDataComponent<IInsightsProperties> {
  isCosted(): boolean {
    return !!this.props.insights?.current_week.metadata.costed;
  }

  hotOrCold(temperatureString: string) {
    const temperature = Number.parseFloat(temperatureString);
    if (!Number.isNaN(temperature)) {
      if (temperature > 30.0) {
        return "\u2600";
      } else if (temperature < 15.6) {
        return "\u2744";
      }
    }
    return "";
  }

  temperatureFill(temperature: number) {
    return temperature > 30 ? "#ffc233" : "#33c2ff";
  }

  averageTooltipHTML(): { title: string; content: string } {
    return { title: 'Average', content: 'Calculated on daily usage for that day over the last 5 weeks.' };
  }

  drawChart() {
    // Magic number so that nothing covers the weather icons
    const topOffset = 52;
    // bit of data transformation
    const weekIntervals: ITrustWeekIntervalCollection =
      currentWeekFromInsights(this.props)?.intervals || {};
    const weekIntervalValues: { [key: string]: number }[] = Object.keys(
      weekIntervals
    )
      .sort((a, b) => parseInt(a) - parseInt(b))
      .map((k: string, i) => {
        const defaultedData = defaultZeroesForInsightsValues(
          weekIntervals[k].data
        );
        defaultedData.day = i;

        if (defaultedData.solar_input > 0) {
          defaultedData.solar_input = 0 - defaultedData.solar_input;
          this.hasSolar = true;
        }

        return defaultedData;
      });

    // Used to create the legend and color the chart
    const usage: Array<{
      label: string;
      key: string;
      backgroundColor: string;
    }> = [];
    var tooltip = d3
      .select(this.componentRef.current)
      .select(".tooltip-container");
    // var legendTooltip = d3
    //   .select(this.componentRef.current)
    //   .select(".legend-tooltip-container");

    if (weekIntervalValues.some((d) => d.solar_input < 0)) {
      usage.push({
        label: "Solar export",
        key: "solar_input",
        backgroundColor: colours.whiteLabelSolarExport,
      });
    }

    if (weekIntervalValues.some((d) => d.grid_spend > 0)) {
      usage.push({
        label: "Usage",
        key: "grid_spend",
        backgroundColor: colours.whiteLabelUsage,
      });
    }

    if (weekIntervalValues.some((d) => (d.service_charge || 0) > 0)) {
      usage.push({
        label: "Supply",
        key: "service_charge",
        backgroundColor: colours.whiteLabelSupply,
      });
    }

    if (weekIntervalValues.some((d) => d.average_spend !== 0)) {
      usage.push({
        label: "Daily average",
        key: "average_spend",
        backgroundColor: colours.whiteLabelAverage,
      });
    }

    var stack = d3
      .stack()
      .keys(usage.map((u) => u.key))
      .offset(d3.stackOffsetDiverging)
      .order((series) => {
        const keys = series.map((s) => (s as unknown as { key: string }).key);

        const order = [];

        const serviceChargIndex = keys.indexOf("service_charge");

        if (serviceChargIndex !== -1) {
          order.push(serviceChargIndex);
        }

        order.push(keys.indexOf("grid_spend"));

        const solarIndex = keys.indexOf("solar_input");

        if (solarIndex !== -1) {
          order.push(solarIndex);
        }

        order.push(keys.indexOf("average_spend"));

        return order;
      }); // allows negative
    var layers = stack(weekIntervalValues);
    // Set margins so that the graph sits nicely in the svg that's created
    const margin = { top: 80, right: 60, bottom: 40, left: 50 };
    const width = 970 - margin.left - margin.right;
    const height = 600 - margin.bottom - margin.top;
    const outerHeight = height + margin.top + margin.bottom;
    const strokeWidth = 2;
    // top level svg elements
    var svg = d3
      .select(this.componentRef.current)
      .select(".graph-container")
      .append("svg")
      .attr("class", "usagechart")
      .attr("viewBox", "-40 0 970 600")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("background-color", colours.white)
      .attr("title", "Weekly Usage Graph");
    var g = svg
      .append("g")
      .attr("class", "chart")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
    const min = 3;
    const valuesToConsider: number[] = [];
    let hasSolar = false;

    weekIntervalValues.forEach((d) => {
      if (d.solar_input < 0) {
        hasSolar = true;
        valuesToConsider.push(d.solar_input);
      }

      if (d.average_spend > 0) {
        valuesToConsider.push(d.average_spend);
      }

      if (d.grid_spend + (d.service_charge || 0) > 0) {
        valuesToConsider.push(d.grid_spend + (d.service_charge || 0));
      }
    });

    const averages = weekIntervalValues.map((d) => d.average_spend);

    const maxValue = d3.max(valuesToConsider) || 0;

    const minValue = hasSolar ? d3.min(valuesToConsider.concat(min)) || 0 : 0;

    // scales
    var xScale = d3
      .scaleBand()
      .range([0, width])
      .paddingInner(0.3)
      .domain(
        weekIntervalValues.map((d) => this.dayLabels[d.day as number].short)
      );
    var yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([minValue, maxValue])
      .nice();
    // background bars
    var bars = g
      .append("g")
      .attr("class", "bars")
      .selectAll(".bar")
      .data(weekIntervalValues)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d): number => {
        let label: string;
        if (d.day !== null && d.day !== undefined) {
          label = this.dayLabels[d.day].short;
        } else {
          label = "";
        }

        return xScale(label) || 0;
      })
      .attr("title", (d) => {
        if (d.day !== null && d.day !== undefined) {
          return `${this.dayLabels[d.day].long} Graph Bar`;
        }

        return "";
      })
      .attr("y", -margin.top + strokeWidth + topOffset)
      .attr("width", () => xScale.bandwidth() - strokeWidth)
      .attr("height", outerHeight - strokeWidth * 2)
      .attr("fill", colours.white)
      .style("cursor", "pointer");

    this.initialiseTooltip(bars, tooltip, svg);

    // axes
    var xAxis = d3.axisTop(xScale);
    var yAxis = d3
      .axisLeft(yScale)
      .tickPadding(15)
      .ticks(6)
      .tickFormat((d) =>
        formatNumber(
          d.valueOf(),
          this.props.insights?.current_week.metadata.costed as boolean,
          {},
          true
        )
      );

    g.append("g")
      .attr("class", "x-axis")
      .attr("title", "X Axis")
      .attr("transform", "translate(0, -50)")
      .call(xAxis);
    g.append("g")
      .attr("class", "y-axis")
      .attr("title", "Y Axis")
      .style("font-size", "12px")
      .call(yAxis);
    d3.selectAll(".domain").remove();
    d3.selectAll(".x-axis .tick line").remove();
    d3.selectAll(".y-axis .tick line")
      .attr("x2", width - 2)
      .attr("stroke", (i) =>
        i === 0 ? colours.whiteLabelAxisLineZero : colours.whiteLabelAxisLine
      );
    // legend
    var legend = d3
      .select(this.componentRef.current)
      .select(".legend-container")
      .append("div")
      .attr("class", "legend")
      .style("width", "100%")
      .append("ul");
    var keys = legend
      .selectAll(".key")
      .data(layers)
      .enter()
      .append("li")
      .attr("class", "key");
    var keyTitles = keys.append("div").attr("class", "title");
    keyTitles
      .append("svg")
      .attr("width", 14)
      .attr("height", 14)
      .append("circle")
      .attr("cx", 7)
      .attr("cy", 7)
      .attr("r", 7)
      .attr("stroke", "black")
      .attr("stroke-width", (d) => (d.key !== "average" ? 0 : 1))
      .style("fill", (d) => {
        switch (d.key) {
          case "average_spend":
            return colours.whiteLabelAverage;
          case "solar_input":
            return colours.whiteLabelSolarExport;
          case "service_charge":
            return colours.whiteLabelDailySupply;
          default:
            return colours.whiteLabelUsage;
        }
      });
    keyTitles
      .append("p")
      .html((d, i) => {
        return usage[i].label;
      })
      .each(function (d, i) {
        d3.select(this)
          .append("img")
          .attr('class', `legendtooltipimg legend-info ${usage[i].label.replace(/\s+/g, '').toLowerCase()}`)
          .attr("src", (d, i) => {
            return "https://cdn.billcap.com/momentum/info-icon.png";
          })
          .style("margin-left", "7px");
      });
    g.append("g")
      .attr("class", "layers")
      .selectAll(".layer")
      .data(layers.filter((d) => d.key !== "average_spend"))
      .enter()
      .append("g")
      .attr("class", "layer")
      .attr("fill", (d) => {
        switch (d.key) {
          case "average":
            return colours.whiteLabelAverage;
          case "solar_input":
            return colours.whiteLabelSolarExport;
          case "service_charge":
            return colours.whiteLabelDailySupply;
          default:
            return colours.whiteLabelUsage;
        }
      })
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (name, value) => xScale(this.dayLabels[value].short) || 0)
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => {
        return yScale(d[0]) - yScale(d[1]);
      })
      .attr("width", () => xScale.bandwidth() - strokeWidth)
      .attr("pointer-events", "none");
    // averages
    g.append("g")
      .attr("class", "averages")
      .selectAll(".average")
      .data(averages)
      .enter()
      .append("circle")
      .attr(
        "cx",
        (d, value) =>
          (xScale(this.dayLabels[value].short) || 0) + xScale.bandwidth() / 2
      )
      .attr("cy", (d, index) => yScale(weekIntervalValues[index].average_spend))
      .attr("r", 6)
      .attr("fill", colours.whiteLabelAverage)
      .attr("class", (d, index) => {
        if (
          weekIntervalValues[index].grid_spend === 0 &&
          weekIntervalValues[index].average_spend === 0
        ) {
          return "hidden";
        } else {
          return "";
        }
      })
      .attr("stroke", "white")
      .attr("stroke-width", "1");
    // Weather icons
    // g.append("g")
    //   .attr('class', 'weatherIcons')
    //   .selectAll(".icon")
    //   .data(weekIntervalValues)
    //   .enter()
    //   .append("text")
    //   .attr("class", "icon")
    //   .attr("x", (d) => (xScale(this.dayLabels[d.day].short) || 0) + 36)
    //   .attr("y", -margin.top + 48)
    //   .style("cursor", "pointer")
    //   .style("font", "bold")
    //   .text((d) => this.hotOrCold(`${d.temperature}`))
    //   .style("fill", (d) => this.temperatureFill(d.temperature));
  }


  tooltipHTML(data: { [key: string]: number }): string {
    const costed = this.isCosted();
    return `<div class="graph-tooltip" title="Tooltip for ${
      this.dayLabels[data.day].long
    }">
    <div class="tooltip-title">
      ${this.dayLabels[data.day].long}
    </div>
    <div class="tooltip-subtitle">
    ${
      costed
        ? `Cost ${formatNumber(data.total_spend, costed)}`
        : formatNumber(data.total_spend, costed)
    }
  </div>
 <div class="tooltip-data">
      <div class="usage data-point"></div>
     <div>Usage ${formatNumber(data.grid_spend, costed)}</div>

      ${
        costed
          ? `<div class="supply data-point"></div>
     <div>Supply ${formatNumber(data.service_charge || 0, costed)}</div>`
          : ""
      }
     ${
       this.hasSolar
         ? `
         <div class="solar data-point"></div>
         <div>Solar ${formatNumber(data.solar_input, costed)}</div>
         `
         : ""
     }
     <div class="average data-point"></div>
         <div>Average ${formatNumber(data.average_spend, costed)}</div>
     </div>
  </div>`;
  }

  // legendTooltipHTML(data: string): string {
  //   return `<div class="legend-tooltip" title="Tooltip">
  //   <div class="tooltip-data">
  //   ${data}
  //   </div>
  //   </div>`;
  // }
}
