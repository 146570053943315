import {format} from "date-fns";
import {ReactElement} from "react";
import IHottestColdestDay from "../../interfaces/insights/IHottestColdestDay";
import InsightComponent from "./InsightComponent";

export default abstract class TemperatureInsight extends InsightComponent<IHottestColdestDay> {
  abstract temperatureType(): string;

  get date(): Date {
    return new Date(this.props.day);
  }

  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/hottest_coldest_day.png';
  }

  headline(): string {
    return format(this.date, 'EEEE');
  }

  byline(): ReactElement {
    return (<>was the { this.temperatureType() } day ({this.props.temperature}&deg;C)</>);
  }
  title(): string {
    return `${this.temperatureType()} day`;
  }
}
