import React, { Context } from "react";
import { TrustService } from "../services/TrustService";
import ITrustState from "../interfaces/ITrustState";
import IUsageProps from "../interfaces/IUsageProps";
import {
  currentWeekFromInsights,
  currentWeekInsights,
  toDateString,
} from "../services/Utils";
import UsageData from "../components/UsageData";
import ITrustInsights, {
  ITrustWeekIntervalCollection,
} from "../interfaces/ITrustInsights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./Usage.scss";
import { TopSummary } from "../components/TopSummary";
import InsightsTypeLinks from "../components/InsightsTypeLinks";
import SummaryNav from "../components/SummaryNav";
import DrillDown from "../components/DrillDown";
import InsightsPanel from "../components/InsightsPanel";
import classNames from "classnames";
import Rollbar from "rollbar";
import {
  ContextInterface,
  getRollbarFromContext,
  RollbarContext,
} from "@rollbar/react";

export class Usage extends React.Component<IUsageProps, ITrustState> {
  static contextType = RollbarContext.contextType;
  rollbar: Rollbar | undefined;
  constructor(props: IUsageProps) {
    super(props);

    this.state = {
      accountId: this.props.accountId,
      fromDate: this.props.fromDate,
      initialising: true,
      error: true,
      costed: this.props.costed,
      loading: true,
      selectedDay: this.props.selectedDay,
    };
  }

  topLevelInsights(): { [key: string]: any } {
    const insights: { [key: string]: any } = {};
    const currentInsights = currentWeekInsights(this.state);

    if (!currentInsights) {
      return [];
    }

    const topTwo = currentInsights?.top_three.slice(0, 2) as {
      [key: string]: any;
    }[];
    topTwo.forEach(
      (insight) =>
        (insights[Object.keys(insight)[0]] = insight[Object.keys(insight)[0]])
    );
    insights["household_size_benchmark"] =
      currentInsights?.fixed_insights.household_size_benchmark;

    if (
      Object.values(
        currentWeekFromInsights(this.state)
          ?.intervals as ITrustWeekIntervalCollection
      ).some((e) => e.metadata.has_solar)
    ) {
      insights["weekly_solar_export"] =
        currentInsights?.fixed_insights.weekly_solar_export;
      insights["solar_trend"] =
        currentInsights?.fixed_insights.weekly_solar_export;
    }

    return insights;
  }

  propsChangedRequireRefetch(prevProps: IUsageProps) {
    return (
      toDateString(this.props.fromDate as Date) !==
        toDateString(prevProps.fromDate as Date) ||
      this.props.costed !== prevProps.costed
    );
  }

  fetchInsightsAndUpdateState(overrides: {
    accountId?: string;
    fromDate?: Date;
    costed?: boolean;
  }): void {
    const costedOverride = overrides.costed;
    const costedParam =
      costedOverride === true || costedOverride === false
        ? costedOverride
        : (this.props.costed as boolean);
    const fromDateParam = (overrides.fromDate || this.props.fromDate) as Date;

    fetchInsights(
      overrides.accountId || (this.state.accountId as string),
      fromDateParam,
      costedParam
    )
      .then((newState: ITrustState) => {
        this.setState(
          Object.assign(this.state, newState, {
            selectedDay: null,
            reloading: false,
          })
        );
      })
      .catch((error) => this.handleFetchError(error));
  }
  handleFetchError(error: any): any {
    if (error === TrustService.accountNotFound) {
      window.location.href = "/accountNotFound";
    } else {
      this.unexpectedError(error);
    }
  }

  unexpectedError(error: any) {
    this.rollbar?.error(error);
    const header = document.querySelector(".header") as HTMLElement;
    const computedStyle = getComputedStyle(header);
    const messageTop =
      parseInt(computedStyle.height) +
      parseInt(computedStyle.paddingTop) +
      parseInt(computedStyle.paddingBottom) +
      header.getBoundingClientRect().top +
      10;
    (
      document.querySelector(".trust-app .alert") as HTMLElement
    ).style.setProperty("--alert-message-top", `${messageTop}px`);
    this.setState(
      Object.assign({}, this.state, {
        insights: this.state.insights || {},
        reloading: false,
        loading: false,
        alert: {
          status: "error",
          message: "Error fetching data. Please try again later",
        },
      })
    );
  }

  componentDidMount(): void {
    this.rollbar = getRollbarFromContext(
      this.context as Context<ContextInterface>
    );
    setTimeout(() => {
      this.fetchInsightsAndUpdateState({ fromDate: this.props.fromDate });
    }, 500);
  }

  componentWillUpdate(prevProps: IUsageProps) {
    if (this.propsChangedRequireRefetch(prevProps)) {
      this.setState({
        reloading: true,
        fromDate: this.props.fromDate,
        selectedDay: this.props.selectedDay,
      });
      setTimeout(() => {
        this.fetchInsightsAndUpdateState({ fromDate: this.props.fromDate });
      }, 500);
    }
  }

  closeMessage(): void {
    this.setState(Object.assign({}, this.state, { message: undefined }));
  }

  render() {
    if (this.state.alert) {
      setTimeout(
        () =>
          this.setState(Object.assign({}, this.state, { alert: undefined })),
        10000
      );
    }
    return (
      <div className="container">
        <div
          className={classNames({
            "trust-app": true,
            loading: !this.state.insights,
          })}
        >
          <div
            className={classNames({
              alert: true,
              fixed: true,
              hidden: !this.state.alert,
              ok: this.state.alert?.status === "ok",
              error: this.state.alert?.status === "error",
            })}
          >
            <span>{this.state.alert?.message}</span>
            <button onClick={(_e) => this.closeMessage()}>&#10006;</button>
          </div>

          <Header />
          {this.state.insights ? (
            <React.Fragment>
              {process.env.REACT_APP_DEBUG_CSS === "true" ? (
                <div className="screenSize">
                  screen size: {window.innerWidth} x {window.innerHeight}
                </div>
              ) : (
                ""
              )}

              <TopSummary
                fromDate={this.props.fromDate as Date}
                accountId={this.props.accountId as string}
                insights={this.state.insights}
                hourlyView={!!this.props.selectedDay}
                selectedDay={this.props.selectedDay}
                costed={this.props.costed as boolean}
              />
              <InsightsPanel
                insights={
                  this.topLevelInsights() as Array<{ [key: string]: any }>
                }
              />
              <div className="usage-graph-container">
                <InsightsTypeLinks
                  fromDate={this.props.fromDate as Date}
                  accountId={this.props.accountId as string}
                  insights={this.state.insights}
                  hourlyView={!!this.props.selectedDay}
                  selectedDay={this.props.selectedDay}
                  costed={this.props.costed as boolean}
                />
                <SummaryNav
                  fromDate={this.props.fromDate as Date}
                  accountId={this.props.accountId as string}
                  insights={this.state.insights}
                  hourlyView={!!this.props.selectedDay}
                  selectedDay={this.props.selectedDay}
                  costed={this.props.costed as boolean}
                  reloading={this.state.reloading}
                />
                <UsageData
                  fromDate={this.props.fromDate as Date}
                  accountId={this.props.accountId as string}
                  insights={this.state.insights as ITrustInsights}
                  hourlyView={!!this.props.selectedDay}
                  selectedDay={this.props.selectedDay}
                  costed={this.props.costed as boolean}
                />
              </div>
              {currentWeekFromInsights(this.state) ? (
                <DrillDown
                  insights={
                    this.state.reloading
                      ? undefined
                      : (this.state.insights as ITrustInsights)
                  }
                  accountId={this.props.accountId as string}
                  fromDate={this.props.fromDate as Date}
                />
              ) : (
                ""
              )}
              <div
                className={classNames({
                  "refetch-loading-panel": true,
                  hidden: !this.state.reloading,
                })}
              >
                <div className="circle-animation"></div>
              </div>
            </React.Fragment>
          ) : (
            <div className="loading-main">
              <div className="circle-animation"></div>
            </div>
          )}
          <Footer accountId={this.props.accountId as string} />
        </div>
      </div>
    );
  }

  oneWeek(): number {
    const oneDay = 24 * 60 * 60 * 1000;
    return 7 * oneDay;
  }

  get currentFromDate(): Date {
    return this.state.fromDate as Date;
  }

  previousWeek(): Date {
    return new Date(this.currentFromDate.getTime() - this.oneWeek());
  }

  nextWeek(): Date {
    return new Date(this.currentFromDate.getTime() + this.oneWeek());
  }

  navigateDate(increment: number, selectedDay: number | undefined): void {
    const newDate = increment === -1 ? this.previousWeek() : this.nextWeek();

    fetchInsights(
      this.state.accountId as string,
      newDate,
      this.state.costed as boolean
    )
      .then((newState: ITrustState) => {
        if (selectedDay) {
          this.setState(
            Object.assign(this.state, newState, { selectedDay: selectedDay })
          );
        } else {
          this.setState(
            Object.assign(this.state, newState, { selectedDay: null })
          );
        }
      })
      .catch((error) => {
        this.handleFetchError(error);
      });
  }

  updateCosted(costed: boolean): void {
    this.fetchInsightsAndUpdateState({ costed: costed });
  }
}

export function fetchInsights(
  accountId: string,
  fromDate: Date,
  costed: boolean
) {
  const service = new TrustService();
  return service.fetchInsights(accountId, fromDate, costed);
}
