import React from 'react';

export default class NoUsageData extends React.Component<{}> {
render() {
    return(<div className="usage-data no-data">
      <div className="message">
        No data available for selected period
      </div>
    </div>);
  }

}
