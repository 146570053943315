import React from "react";
import {Link} from "react-router-dom";
import INavProperties from "../interfaces/INavProperties";
import {costedState, isoDate} from "../services/Utils";
import classNames from 'classnames';
import "./InsightsTypeLinks.scss"

export default class InsightsTypeLinks extends React.Component<INavProperties> {
  get fromDateString() {
    return isoDate(this.props.fromDate);
  }

  get costed() {
    return this.props.insights?.current_week.metadata.costed;
  }

  render() { 
    return(
      <div className="insights-type-links">
        <div className="daily-weekly-link" title="Daily or Weekly View Switch">
        <Link to={ `/usage/${ this.props.accountId }/${costedState(this.props)}/${ this.fromDateString }` } className={ classNames({ dailyToggle: true, activeMode: !this.props.hourlyView }) }>WEEKLY</Link>
        <Link to={ `/usage/${ this.props.accountId }/${costedState(this.props)}/${ this.fromDateString }/0` } className={ classNames({ dailyToggle: true, activeMode: this.props.hourlyView }) }>DAILY</Link>
      </div>
      </div>);
  }
}
