import IWeeklySolarExport from "../../interfaces/insights/IWeeklySolarExport";
import {formatNumber} from "../../services/Utils";
import InsightComponent from "./InsightComponent";

export default class WeeklySolarExport extends InsightComponent<IWeeklySolarExport> {
  iconSrc(): string {
    return 'https://cdn.billcap.com/momentum/weekly_solar_export.png';
  }

  headline(): string {
    if(this.props.costed) {
      return formatNumber(this.props.total_current_week, true);
    } else {
      return formatNumber(this.props.total_current_week, false, { roundingOverride: 0 });
    }
  }

  byline(): string {
    if(this.props.costed) {
      return 'feed-in credits earned';
    }

    return 'solar exported';
  }

  title(): string {
    return 'Weekly Solar Export';
  }
}
