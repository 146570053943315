import { format } from "date-fns";
import IInsightsProperties from "../interfaces/IInsightsProperties";
import { ITrustInsightsIntervalData } from "../interfaces/ITrustInsightsInterval";

export function isValidStartOfWeek(date: Date) {
  if (!isValidDate(date)) {
    return false;
  }

  if (date > new Date()) {
    return false;
  }

  return date.getDay() === 1;
}

export function formatPercentageOrTimes(value: number) {
  if(value < 150) {
    return `${Math.ceil(value)}%`;
  } else {
    const times = (value / 100.0).toFixed(1)
    return `${times}x`;
  }
}

export function usingMobileView(): boolean {
 return window.matchMedia("(max-width: 923px)").matches;
}

export function nonMouseDevice(): boolean {
 // max-width: 500px to capture Samsung mobile as it misreports the hover property
 return window.matchMedia("(hover: none), (orientation: portrait) and (max-width: 500px)").matches
}

export function usingDesktopView(): boolean {
 return window.matchMedia("(min-width: 923px)").matches;
}

export function mondayLastWeek() {
  const oneDay = 24*60*60*1000;
  const oneWeek = 7 * oneDay;
  let defaultDate = new Date(new Date().getTime() - oneWeek);

  while(defaultDate.getDay() !== 1) {
    defaultDate = new Date(defaultDate.getTime() - oneDay);
  }

  return defaultDate;
}

export function sundayLastWeek() {
  const oneDay = 24*60*60*1000;
  let defaultDate = mondayLastWeek();

  while(defaultDate.getDay() !== 0) {
    defaultDate = new Date(defaultDate.getTime() + oneDay);
  }

  return defaultDate;
}


export function isValidDate(date: Date) { 
  return isFinite(date.getTime());
}

export function isoDate(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

function defaultIfNotSet(value: any, defaultValue: any) {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  return value;
}

export function formatNumber(
  insightsAmount: number,
  costed: boolean,
  options?: { roundingOverride?: number; unitDescriptionOverride?: string, ceilNearZeroNonCosted?: boolean, ceil?: boolean },
  fromGraph: boolean = false
) {
  if (insightsAmount === null || insightsAmount === undefined) {
    return "";
  }
  var unitDescription: string = "";
  if (!fromGraph) {
    unitDescription =
      options?.unitDescriptionOverride || (costed ? "$" : "kWh");
  }

  if(options?.ceil) {
    insightsAmount = Math.ceil(insightsAmount);
  }

  if (costed) {
    if (insightsAmount < 0) {
      const roundedValue = Math.abs(insightsAmount).toFixed(
        defaultIfNotSet(options?.roundingOverride, 2)
      );

      if (roundedValue === "0.00") {
        return `${unitDescription}${roundedValue}`;
      } else {
        return `-${unitDescription}${roundedValue}`;
      }
    } else {
      return `${unitDescription}${insightsAmount.toFixed(
        defaultIfNotSet(options?.roundingOverride, 2)
      )}`;
    }
  } else {
    if (
      options?.roundingOverride !== null &&
      options?.roundingOverride !== undefined
    ) {
      let roundedValue = insightsAmount.toFixed(
        options?.roundingOverride
      )

      if(parseFloat(roundedValue) === 0 && options?.ceilNearZeroNonCosted) {
        roundedValue = (insightsAmount < 0 ? -1.00000 : 1.00000).toFixed(
          options?.roundingOverride
        )
      }

      return `${roundedValue} ${unitDescription}`;
    } else {
      return `${insightsAmount} ${unitDescription}`;
    }
  }
}

export function dayLabels() {
  return [
    { short: "Mon", long: "MONDAY" },
    { short: "Tue", long: "TUESDAY" },
    { short: "Wed", long: "WEDNESDAY" },
    { short: "Thu", long: "THURSDAY" },
    { short: "Fri", long: "FRIDAY" },
    { short: "Sat", long: "SATURDAY" },
    { short: "Sun", long: "SUNDAY" },
  ];
}

export function costedState(props: { costed: boolean }) {
  return props.costed ? "cost" : "usage";
}

export function currentWeekInsights(props: IInsightsProperties) {
  return currentWeekFromInsights(props)?.insights;
}

export function currentWeekFromInsights(props: IInsightsProperties) {
  if (props.insights?.current_week?.metadata?.has_data) {
    return props.insights?.current_week;
  }

  return null;
}

export function oneDayMilliseconds() {
  return 24 * 60 * 60 * 1000;
}

export function oneWeekMilliseconds() {
  return oneDayMilliseconds() * 7;
}

export function toDateString(date: Date) {
  return isoDate(date);
}

export function parseYYYYMMDD(yyyymmdd: string) {
  let match: string[] | null = yyyymmdd.match(/(\d{4})(\d{2})(\d{2})/);

  if (!match) {
    throw new Error(`Invalid yyyymmdd: ${yyyymmdd}`);
  }

  match = match as string[];

  match.shift();
  return new Date(match.join("-"));
}

export function defaultZeroesForInsightsValues(
  data: ITrustInsightsIntervalData
): { [key: string]: number } {
  return {
    total_spend: data.total_spend || 0,
    average_spend: data.average_spend || 0,
    solar_input: data.solar_input || 0,
    service_charge: data.service_charge || 0,
    grid_spend: data.grid_spend || 0,
    temperature: data.temperature || 0,
    solar_kwh: data.solar_kwh || 0,
  };
}
