import {Navigate, useParams} from "react-router-dom";
import DefaultFromDateNavigation from "../pages/DefaultFromDateNavigation";
import {Usage} from "../pages/Usage";
import {isValidStartOfWeek} from "../services/Utils";

function MainComponent(props: { costed: boolean}) {
  const { accountId, fromDate, selectedDay } = useParams();
  const fromDateAsDate = new Date(fromDate as string);

  let selectedDayProp: string | undefined = selectedDay;

  if(selectedDayProp && !selectedDayProp.match(/^[0-6]$/)) {
    return (<Navigate to={ `/usage/${accountId}/usage/${fromDate}` } replace={true} />);
  }

  if(isValidStartOfWeek(fromDateAsDate)) {
    return (<Usage accountId={ accountId } fromDate={ new Date(fromDate as string) } selectedDay={ selectedDayProp } costed={ props.costed } />);
  }

  return (<DefaultFromDateNavigation/>)
}

export default MainComponent;
